import React from 'react';
import Layout from '../components/layout';

const pageUrls = require('../constants')

const NotFoundPage = () => (
  <>
    <Layout nav="colored-nav">
      <section className="mt130 text-center">
        <h1>Looks like there's nothing here.</h1>
        <p>
          Perhaps try using one of the links above or return to the <a href={pageUrls.HOME_URL}>homepage</a>.
        </p>
      </section>
    </Layout>
  </>
);

export default NotFoundPage;
